<template>
    <div>
      <div style="width: 100%; border-bottom: .5px solid #e6e6e6;">
        <nav class="navbar">
          <div style="width: 100%;">
            <div v-if="lala === 0">
              <el-row :gutter="20">
                <el-col :span="4" v-for="el in 30" :key="el">
                  <el-skeleton style="width: 240px" :loading="loading" animated>
                    <template #template>
                      <el-skeleton-item variant="image" style="width: 240px; height: 240px" />
                      <div style="padding: 14px">
                        <el-skeleton-item variant="p" style="width: 50%" />
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            justify-items: space-between;"
                        >
                          <el-skeleton-item variant="text" style="margin-right: 16px" />
                          <el-skeleton-item variant="text" style="width: 30%" />
                        </div>
                      </div>
                    </template>
                  </el-skeleton>
                </el-col>
              </el-row>
            </div>
            <div v-else style="width: 100%; display: ruby">
              <div style="display: ruby">
                <div style="width: 340px; padding: 8px" v-for="item in msg" :key="item.id_item">
                  <div class="image-item" style="width: 100%" @click="changeBiens(item)">
                      <el-carousel height="300px" :autoplay="false">
                          <el-carousel-item>
                              <img :src="item.image1" alt="Property Image 1">
                          </el-carousel-item>
                          <el-carousel-item>
                              <img :src="item.image2" alt="Property Image 1">
                          </el-carousel-item>
                          <el-carousel-item>
                              <img :src="item.image3" alt="Property Image 1">
                          </el-carousel-item>
                          <el-carousel-item>
                              <img :src="item.image4" alt="Property Image 1">
                          </el-carousel-item>
                          <el-carousel-item>
                              <img :src="item.image5" alt="Property Image 1">
                          </el-carousel-item>
                          <div class="tag">Résidence</div>
                      </el-carousel>
                  </div>
                  <div class="texte-zone">
                    <p><b>{{item.ville_item}}, {{item.commune_item}}</b></p>
                    <p style="color: gray">{{item.libelle}}</p>
                    <p><b>{{item.tarif_jour}} FCFA</b> / par nuit</p>
                  </div>
                </div>
              </div>
             
            </div>
            
          </div>
        </nav>
      </div>
    </div> 
  </template>  
  
  <script>
  import { isMobile } from 'mobile-device-detect';
  import { isTablet } from 'mobile-device-detect';
  import { isBrowser } from 'mobile-device-detect';
  export default {
    name: 'AccueilNavbar',
    props: {
        msg: Array
    },
    computed: {
        biens() {
            return this.$store.state.donneesBiens;
        },
    },
    data() {
      return {
        lala: 0,
        loading: true,
        ismobile: isMobile,
        istablet: isTablet,
        isbrowser: isBrowser,
        width: window.innerWidth,
        height: window.innerHeight,

      };
    },
    methods: {
      skeleton() {
        setTimeout(() => {
          this.lala = 1;
        }, 2000);
      },

      updateDimensions() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      },

      changeBiens(bien) {
        console.log(bien.id_item);
        this.$router.push({ name: 'detail', params: { param: bien.id_item } });
      // Déclencher la mutation pour stocker les détails du bien
      //this.$store.commit("setDonneesBien", bien);
      // Naviguer vers la page de détails en passant l'identifiant du bien en paramètre
    },

    },
    mounted() {
      this.skeleton();
      window.addEventListener('resize', this.updateDimensions);
    }
  };
  </script>
  
  <style scoped>
  /* Styles spécifiques au composant */
  .navbar {
    top: 0;
    width: 100%;
    padding: 10px;
    max-width: 1880px;
    margin: 0 auto;
    z-index: 1000;
    background: white;
  }
  
  .image-item2 {
    position: relative; /* Position relative pour le positionnement absolu du tag */
    text-align: center; /* Centrer les images horizontalement */
    height: 200px; /* Hauteur fixe pour toutes les images */
    overflow: hidden; /* Masquer tout contenu excédant la hauteur fixée */
    border-radius: 15px;
  }

  .image-item {
    position: relative; /* Position relative pour le positionnement absolu du tag */
    text-align: center; /* Centrer les images horizontalement */
    height: 300px; /* Hauteur fixe pour toutes les images */
    overflow: hidden; /* Masquer tout contenu excédant la hauteur fixée */
    border-radius: 15px;
  }
  
  .image-item img {
    width: 100%; /* Assurez-vous que l'image occupe toute la largeur disponible */
    height: 100%; /* Ajuste automatiquement la hauteur pour maintenir le ratio d'aspect */
    object-fit: cover; /* Zoomer sur l'image tout en conservant le ratio d'aspect */
  }
  
  .tag {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: #74716ba1;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
  }
  
  .texte-zone {
    text-align: left; /* Alignement du texte à gauche */
    padding-top: 10px;
    padding-bottom: 20px; /* Espacement interne */
  }
  
  .texte-zone p {
    margin: 5px 0;
    line-height: 1.2; /* Espacement entre les lignes de texte */
  }

  .certification-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: green; /* Couleur de fond pour le tag */
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
  }

  .el-carousel__button {
    width: 5px;
  }
  </style>
  