<template>
  <div>
    <div style="width: 100%; border-bottom: .5px solid #e6e6e6;">
      <nav class="navbar">
        <div class="button-container">
          <button 
            class="button button-filtre" 
            v-for="item in filtre_list" 
            :key="item.libelle"
            :class="{ active: item.active }"
            @click="toggleActive(item)"
          >
            <span class="icon" style="color:black; width: 100%; margin-left: 2px; text-align:center">
              <i :class="item.icon" style="color: gray; font-size: 25px"></i>
            </span>
            <span style="color: gray">{{item.libelle}}</span>
          </button>
        </div>
      </nav>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AccueilFilter',
  data() {
    return {
      filtre_list: [
        { libelle: 'Piscine', icon: 'fa-solid fa-water-ladder', active: false },
        { libelle: 'Internet', icon: 'fa-solid fa-wifi', active: false },
        { libelle: 'Balcon', icon: 'fa-solid fa-dungeon', active: false },
        { libelle: 'Terasse', icon: 'fa-solid fa-warehouse', active: false },
        { libelle: 'Jaccuzi', icon: 'fa-solid fa-hot-tub-person', active: false },
        { libelle: 'Parking', icon: 'fa-solid fa-square-parking', active: false },
        { libelle: 'Sécurité', icon: 'fa-solid fa-shield-halved', active: false },
        { libelle: 'Bar', icon: 'fa-solid fa-champagne-glasses', active: false },
        { libelle: 'Bord Mer', icon: 'fa-solid fa-water', active: false },
        { libelle: 'Bord Route', icon: 'fa-solid fa-road-bridge', active: false }
      ]
    };
  },
  methods: {
    toggleActive(item) {
      item.active = !item.active;
    }
  }
};

</script>

<style scoped>
.navbar {
  width: 100%;
  padding: 10px;
  max-width: 100%;
  margin: 0 auto;
  z-index: 1000;
  background: white;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.button-filtre {
  height: 70px;
  width: 90px;
  margin: 5px;
  display: inline-grid;
  transition: background-color 0.3s, color 0.3s;
}

.button-filtre.active {
  background-color: rgba(120, 228, 255, 0.808);
  color: white;
}
</style>

