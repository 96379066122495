import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    loginActive: true,
    LoginPage: true,
    biens: {
      datas: [],
      total_row: 0,
      total_pages: 0,
      rtype: '0',
      rville: '0',
      rquartier: '0',
      rpage: 1,
    },
    donneesBiens: [],
    listGrid: true,
    langueDevise: {
      langue: 'fr',
      devise: 'XOF'
    },
    rechercheInfos: {
      destination: 'Abidjan',
      du: '',
      au: '',
      adulte: 1,
      enfant: 1,
      date: []
    },
    uniquser: '',
    users: {
      nom: '',
      prenom: '',
      email: '',
      contact: '',
      photo: '',
      statut: '',
      id_user: null,
      agent: 'KO',
    },
    formulaire: {
      hote: {
        nom_hote: null,
        contact1_hote: 0,
        contact2_hote: 0,
        email: '',
        texte_visiteur: '',
        photo: 'https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1'
      },
      images: {
        image1: 'http://res.cloudinary.com/r-sidence-meubl-e/image/upload/v1719916169/202462102999027.jpg',
        image2: 'a',
        image3: 'a',
        image4: 'a',
        image5: 'a',
        image6: 'a',
        image7: 'a',
        image8: 'a',
        image_id1: '1',
        image_id2: '1',
        image_id3: '1',
        image_id4: '1',
        image_id5: '1',
        image_id6: '1',
        image_id7: '1',
        image_id8: '1',
      },
      adresse: {
        ville: 'Abidjan',
        commune: 'Cocody',
        adresse: ''
      },
      residence: {
        libelle: '',
        prix_nuit: '',
        type: '',
        reduction: 'Non',
        prix_reduction: '',
        duree_reduction: '',
        serch_zone: '',
        description: '',
        user: 1
      },
      services: {
        espace: false,
        restaurant: false,
        bar: false,
        petit_dej: false
      },
      service_icon: [],
      espace: [],
      restaurant: [],
      bar: [],
      petit_dej: [],
    },

    
    alert: {
      hote: {
        nom: false,
        contact1_hote: false,
        contact2_hote: false
      },
      residence: {
        libelle: false,
        prix_nuit: false,
        type: false
      },
      adresse: {
        ville: false,
        commune: false,
      },
      images: {
        image5: false,
        limite: false
      }
    }
  },
  getters: {
    // Exemple de getter pour accéder aux donnéesBiens
    getDonneesBiens: state => state.donneesBiens,
    getalert: state => state.alert,
  },
  mutations: {

    setRechercheInfos(state, data) {
      state.rechercheInfos.destination = data.destination;
      state.rechercheInfos.du = data.du;
      state.rechercheInfos.au = data.au;
      state.rechercheInfos.adulte = data.adulte;
      state.rechercheInfos.enfant = data.enfant;
    },

    setRechercheInfos2(state, data) {
      state.rechercheInfos.destination = data.destination;
      state.rechercheInfos.date = data.date;
      state.rechercheInfos.adulte = data.adulte;
      state.rechercheInfos.enfant = data.enfant;
    },

    setDatas(state, data) {
      state.biens.datas = data.datas;
      state.biens.total_row = data.total_row;
      state.biens.total_pages = data.total_pages;
      state.biens.rtype = data.rtype;
      state.biens.rville = data.rville;
      state.biens.rquartier = data.rquartier;
      state.biens.rpage = data.rpage;
    },

    setLanguage(state, langue) {
      state.langueDevise.langue = langue;
    },

    setLogin(state, data) {
      state.loginActive = data;
    },

    setLoginPage(state, data) {
      state.LoginPage = data;
    },

    Preferences: (state, data) => {
      state.langueDevise.langue = data.langue;
      state.langueDevise.devise = data.devise;
    },

    ConnexionAgent: (state, data) => {
      state.users.nom = data.nom;
      state.users.prenom = data.prenom;
      state.users.email = data.email;
      state.users.contact = data.contact;
      state.users.photo = data.photo;
      state.users.statut = data.statut;
      state.users.id_user = data.id_user;
      state.users.agent = data.agent;
    },

    StatutAgent: (state, data) => {
      state.users.agent = data;
    },
    // Mutations pour mettre à jour donneesBiens
    setDonneesBien(state, bien) {
      state.donneesBiens = bien;
    },
    setalert(state, alert) {
      state.alert = alert;
    },
    setListGrid(state, etat) {
      state.listGrid = etat;
    },
  },
  actions: {
    // Exemple d'action pour mettre à jour donneesBiens
    updateDonneesBien({ commit }, bien) {
      commit('setDonneesBien', bien);
    },
  },
  plugins: [createPersistedState()], // Utilisation de vuex-persistedstate
  modules: {
    // Modules Vuex si nécessaire
  },
});
