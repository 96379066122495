<template>
    <div>
      <div style="width: 100%; border-bottom: .5px solid #e6e6e6;">
        <nav class="navbar">
          <div style="width: 100%;">
            <div v-if="lala === 0">
              <el-row :gutter="24">
                <el-col :span="24" v-for="el in 5" :key="el">
                  <el-skeleton style="width: 100%" :loading="loading" animated>
                    <template #template>
                      <el-row :gutter="24">
                        <el-col :span="12">
                          <el-skeleton-item variant="image" style="width: 100%; height: 110px; margin-bottom: 10px" />
                        </el-col>
                        <el-col :span="12">
                          <div style="padding: 14px">
                            <el-skeleton-item variant="p" style="width: 80%" />
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                justify-items: space-between;"
                            >
                              <el-skeleton-item variant="text" style="margin-right: 16px" />
                              <el-skeleton-item variant="text" style="width: 30%" />
                            </div>
                          </div>
                        </el-col>
                      </el-row>
                    </template>
                  </el-skeleton>
                </el-col>
              </el-row>
            </div>
            <div v-else style="width: 100%; display: ruby; margin-bottom: 100px">
              
              <el-card
                class="result-item"
                v-for="item in msg"
                :key="item.id_item"
                style="margin: 2px auto; max-width: 400px; padding: 0px; padding-bottom: 0px"
              >
                <div @click="changeBiens(item.residence.id_resid)" style="color: black; padding: 0px; margin: -15px">
                  <el-row>
                    <el-col :span="12" style="display: flex">
                      <img
                        class="img"
                        :src="item.images[0].lien"
                        style="width: 300px !important; height:132px; border-radius: 5px;"
                      />
                    </el-col>
                    <el-col :span="12">
                      <div class="result-item-detail">
                        <span class="result-title-m">{{ item.residence.libelle_resid }}</span>
                        <span class="result-location-m" style="color: orange" v-if="item.residence.ville_resid === 'Abidjan'">
                          <el-icon style="color: orange"><LocationFilled /></el-icon>
                          {{ item.residence.commune_resid }},
                          <em style="color: #67c23a; font-weight: bold">{{ item.residence.adresse_resid}}</em>
                        </span>
                        <span class="result-location-m" style="color: orange" v-else>
                          <el-icon style="color: orange"><LocationFilled /></el-icon>
                          {{ item.residence.ville_resid }},
                          <em style="color: #67c23a; font-weight: bold">{{ item.residence.adresse_resid }}</em>
                        </span>
                        <div class="services-list-m mt-2" style="margin-top: 5px; text-align: left;">
                          <span v-for="service in item.service_icon" :key="service.id_icon">
                            <i v-if="service.libelle == 'Internet' || service.libelle == 'Parking' || service.libelle == 'Climatisation' || service.libelle == 'Moustiquaire' || service.libelle == 'Bord Route'" style="padding: 4px" :class="service.icon" aria-hidden="true"></i>
                          </span>
                        </div>
                        <div class="mt-2" style="text-align: left;">
                          <span
                            class="result-title-m"
                            style="font-weight: bold; font-size: 13px; color: #348ddb; position: absolute"
                           
                          >{{ formatMoney(item.residence.prix_nuit) }} <em style="font-size: 12px">/ nuit</em>
                          </span>
                          <span
                            class="result-title-m"
                            style="color: gray; font-size: 10px; text-align: center; position: absolute; margin-top: 20px;"
                            v-if="item.residence.duree_reduction.length >= 1"
                          >
                            Réduction à partir de {{ item.residence.duree_reduction }}
                          </span><br />
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-card>
             
            </div>
            
          </div>
        </nav>
      </div>
    </div>
  </template>  
  
  <script>
   // Importer Swiper et SwiperSlide depuis swiper/vue
  export default {
    name: 'AccueilNavbar',
    props: {
        msg: Array
    },
    computed: {
        biens() {
            return this.$store.state.donneesBiens;
        },
        preference() {
        return this.$store.state.langueDevise;
      }
    },
    data() {
      return {
        lala: 0,
        loading: true,
        width: window.innerWidth,
        height: window.innerHeight,
        swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
      },

      };
    },
    methods: {
      skeleton() {
        setTimeout(() => {
          this.lala = 1;
        }, 2000);
      },

      formatMoney(amount) {
        if (isNaN(amount)) {
          return amount;
        }
        return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'XOF'
        }).format(amount);
      },

      updateDimensions() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      },

      changeBiens(bien) {
        console.log(bien);
        this.$router.push({ name: 'detail', params: { param: bien } });
      // Déclencher la mutation pour stocker les détails du bien
      //this.$store.commit("setDonneesBien", bien);
      // Naviguer vers la page de détails en passant l'identifiant du bien en paramètre
    },

    scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth' // Permet un défilement fluide
			});
		},

    },
    mounted() {
      this.skeleton();
      window.addEventListener('resize', this.updateDimensions);
    }
  };
  </script>
  
  <style scoped>
  /* Styles spécifiques au composant */
  .navbar {
    top: 0;
    width: 100%;
    padding: 10px;
    max-width: 1880px;
    margin: 0 auto;
    z-index: 1000;
    background: white;
    font-size: 15px;
    color: black
  }
  
  .image-item2 {
    position: relative; /* Position relative pour le positionnement absolu du tag */
    text-align: center; /* Centrer les images horizontalement */
    height: 200px; /* Hauteur fixe pour toutes les images */
    overflow: hidden; /* Masquer tout contenu excédant la hauteur fixée */
    border-radius: 15px;
  }

  .image-item {
    position: relative; /* Position relative pour le positionnement absolu du tag */
    text-align: center; /* Centrer les images horizontalement */
    height: 300px; /* Hauteur fixe pour toutes les images */
    overflow: hidden; /* Masquer tout contenu excédant la hauteur fixée */
    border-radius: 15px;
  }
  
  .image-item img {
    width: 100%; /* Assurez-vous que l'image occupe toute la largeur disponible */
    height: 300px; /* Ajuste automatiquement la hauteur pour maintenir le ratio d'aspect */
    object-fit: cover; /* Zoomer sur l'image tout en conservant le ratio d'aspect */
  }
  
  .tag {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: #74716ba1;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
  }
  
  .texte-zone {
    text-align: left; /* Alignement du texte à gauche */
    padding-top: 10px;
    padding-bottom: 20px; /* Espacement interne */
  }
  
  .texte-zone p {
    margin: 5px 0;
    line-height: 1.2; /* Espacement entre les lignes de texte */
  }

  .certification-tag {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: green; /* Couleur de fond pour le tag */
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
  }

  .el-carousel__button {
    width: 5px;
  }

  .el-card__body {
    padding: 0px
  }
  </style>
  