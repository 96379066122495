<template>
    <div>
      <!-- Fixebar avec NavbarWeb et FilterWeb -->
      <div class="fixebar">
        <NavbarWeb msg="Welcome to Your Vue.js App"/>
        <FilterWeb msg="Welcome to Your Vue.js App"/>
       
      </div>
  
      <!-- ListeWeb -->
      <div class="list-container">
        <ListeWeb :msg="datas"/>
      </div>
    </div>
  </template>
  
  <script>
  import NavbarWeb from '@/components/accueil/accueil_web/Navbar.vue'
  import FilterWeb from '@/components/accueil/accueil_web/Filter.vue'
 // import ImageBan from '@/components/accueil/accueil_web/Image-ban.vue'
  import ListeWeb from '@/components/accueil/accueil_web/Liste.vue'
  
  export default {
    name: 'AccueilIndex',
    components: {
      NavbarWeb,
      FilterWeb,
      ListeWeb,
     // ImageBan
    },
    data() {
      return {
        datas: [],
        value1: [1, 80],
        select1: '0',
        select2: '0',
        selectedType: '0',
        filters: {
                piscine: '',
                restaurant: '',
                bordureMer: '',
                evenement: '',
                internet: '',
            },
        // Ajoutez d'autres données si neccessaire
      };
    },
    methods: {
        ListeFiltre2() {
            
            const postData = {
                prix_min: this.value1[0] * 5000,
                prix_max: this.value1[1] * 5000,
                type: this.selectedType,
                ville: this.select1,
                parpage: 20,
                commune: this.select2,
                piscine: this.filters.piscine ? 1 : 0,
                restaurant: this.filters.restaurant ? 1 : 0,
                bordureMer: this.filters.bordureMer ? 1 : 0,
                evenement: this.filters.evenement ? 1 : 0,
                internet: this.filters.internet ? 1 : 0
            };

            // Correction de la valeur de prix
            if (postData.prix === 'Tarif') {
                postData.prix = '0';
            }

            // Correction de la valeur de type
            if (postData.type === 'Types') {
                postData.type = '0';
            }
            // Faire la requête POST
            this.$axios.post('/web_liste_appart.php?page=1', postData)
                .then(response => {
                    // Traiter la réponse de l'API
                    this.datas = response.data.data;
                    console.log(this.datas);
                    //this.$store.commit('ResultatByHome', this.datas);
                })
                .catch(error => {
                    // Gérer les erreurs
                    console.error('Erreur de requête:', error);
                });
        },
    },
    mounted() {
        this.ListeFiltre2();
    }
  };
  </script>
  
  <style scoped>
  /* Styles spécifiques au composant */
  .fixebar {
    position: fixed;
    width: 100%;
    box-shadow: 0 .5px 1px rgba(0,0,0,0.1);
    top: 0;
    left: 0;
    z-index: 1000; /* Plus haut z-index pour rester au-dessus */
  }
  
  .list-container {
    margin-top: 190px; /* Ajustement de la marge pour laisser de la place à fixebar */
    position: relative; /* Position relative pour ajuster le z-index */
    z-index: 900; /* Z-index inférieur à celui de fixebar */
  }
  </style>
  