<template>
  <div>
    <div style="width: 100%; border-bottom: .5px solid #e6e6e6;">
      <nav class="navbar">
        <div class="navbar-brand">
          <a class="navbar-item" href="https://www.airbnb.fr">
            <img src="https://upload.wikimedia.org/wikipedia/commons/6/69/Airbnb_Logo_Bélo.svg" alt="Airbnb Logo">
          </a>
          <div class="navbar-burger burger" @click="toggleNavbar" :class="{'is-active': isActive}">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div class="navbar-menu" :class="{'is-active': isActive}" style="margin-left: 25px;">
          <div class="navbar-start centered-navbar-start">
            <div class="field has-addons" style="margin-top: 10px; width: 100%; max-width: 900px;">
              <p class="control">
                <a class="button is-static" style="background: white;">
                  Destination
                </a>
              </p>
              <el-select
                v-model="rechercheInfos.destination"
                filterable
                remote
                reserve-keyword
                placeholder="Please enter a keyword"
                :remote-method="remoteMethod"
                :loading="loading"
                style="width: 440px;"
                size="large"
                @blur="onDestinationBlur"
              >
                <el-option
                  v-for="item in listZone"
                  :key="item.libelle"
                  :label="item.libelle"
                  :value="item.libelle"
                />
              </el-select>
              <el-date-picker
                ref="datePicker"
                style="margin-left: -10px;"
                v-model="rechercheInfos.date"
                type="daterange"
                range-separator="Au"
                start-placeholder="Date début"
                end-placeholder="Date Fin"
                size="large"
              />
            </div>
          </div>

          <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                <router-link class="navbar-item" href="#" to="/nouvelle_enregistrement">Ajouter ma résidence</router-link>
                <a class="button is-light">
                  Connexion
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccueilNavbar',
  computed: {
    rechercheInfos() {
      return this.$store.state.rechercheInfos;
    },
  },
  data() {
    return {
      destination: '',
      datePeriode: [],
      isActive: false,
      listZone: [
        { libelle: 'Abidjan Cocody' },
        { libelle: 'Abidjan Abobo' },
        { libelle: 'Abidjan Treichville' },
        { libelle: 'Abidjan Portbouet' },
        { libelle: 'Abidjan Yopougon' },
      ]
    };
  },
  created() {
    this.setDefaultDates(); // Appel à la méthode pour initialiser les dates par défaut
  },
  watch: {
    rechercheInfos: {
      handler() {
        this.searchData(); // Call searchData method whenever rechercheInfos changes
      },
      deep: true, // This ensures deep watching of rechercheInfos properties
    },
  },
  methods: {
    toggleNavbar() {
      this.isActive = !this.isActive;
    },
    searchData() {
      const info = {
        destination: this.rechercheInfos.destination,
        date: this.rechercheInfos.date,
        adulte: this.rechercheInfos.adulte,
        enfant: this.rechercheInfos.enfant,
      };
      this.$store.commit('setRechercheInfos2', info);
    },
    onDestinationBlur() {
      if (this.destination) {
        this.$refs.datePicker.focus();
      }
    },
    setDefaultDates() {
      // Calculer la date de demain et après-demain
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      const dayAfterTomorrow = new Date();
      dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

      // Formater les dates au format ISO (YYYY-MM-DD) pour l'élément date-picker
      const tomorrowISO = tomorrow.toISOString().split('T')[0];
      const dayAfterTomorrowISO = dayAfterTomorrow.toISOString().split('T')[0];

      // Définir datePeriode avec ces valeurs
      this.rechercheInfos.date = [tomorrowISO, dayAfterTomorrowISO];
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant */
.navbar {
  top: 0;
  width: 100%;
  max-width: 1880px;
  margin: 0 auto;
  z-index: 1000;
  background: white;
  padding: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.navbar-item img {
  width: 200px;
}
.responsive-input {
  max-width: 500px;
  width: 100%;
}
.centered-navbar-start {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
